@import 'style/variables';

.landingpage {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  position: relative;
  background-color: $charcoal;

  .buttonBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .gradientBox {
    width: 100vw;
    background: linear-gradient(
        180deg,
        rgba(36, 39, 39, 0) 60%,
        rgba(0, 224, 181, 0.12) 100%
      ),
      $charcoal;
  }

  .jumbotron {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 45rem;
    background-image: url('/assets/logo/logoBackground.svg');
    background-position: bottom;
    background-repeat: no-repeat;
    margin-bottom: 1.25rem;
    .header {
      p {
        font-weight: 400;
        font-size: 1.75rem;
        line-height: 2.25rem;
        color: $muiOnPrimaryContainer;
      }
    }
    .headline {
      margin: 3.625rem 0;
      font-weight: 700;
    }
    .etournityLogo {
      width: 80vw;
      max-width: 25.5rem;
      > path {
        fill: $muiOnPrimaryContainer;
      }
    }
    .createButton {
      border-radius: 6.25rem;
      padding: 0.625rem 1.5rem 0.625rem 1rem;
      max-width: 17.5rem;
      max-height: 3rem;
      width: 100%;
      height: 100%;
      > label {
        cursor: inherit;
      }
      :first-child {
        margin-right: 0.5rem;
      }
      p {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-transform: capitalize;
      }
    }
    .label {
      margin-top: 0.9375rem;
    }
  }

  .graphicsContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .headline {
      font-weight: 700;
      margin-bottom: 4rem;
      color: $muiPrimary;
    }
    ul {
      text-align: left;
    }
    .graphicsWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      max-width: 70.5rem;

      img {
        min-width: 20.5rem;
        min-height: 11.375rem;
        width: 100%;
      }
      ul {
        font-weight: 400;
        list-style: disc inside;
        margin: 0.75rem 0 0;
        padding: 0;
        li {
          padding: 0.125rem 0.625rem;
          background-color: inherit;
          border-radius: 1.1875rem;
          transition: all 0.15s;
          color: $muiOnSurface;
        }
      }
      &.matchlobbyBox {
        flex-wrap: wrap-reverse;
        .liHover {
          background-color: rgba(0, 224, 181, 0.12);
          border-radius: 1.1875rem;
          &::marker {
            background-color: rgba(0, 224, 181, 0.12);
          }
        }
      }
      &.hosthubBox {
        margin-bottom: 8.75rem;
      }
    }
  }

  .uspCards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    margin: 7.75rem auto 12.3125rem;
    padding: 1rem 0;
    width: fit-content;
    max-width: 100%;
    position: relative;
    z-index: 4;

    .card {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      text-align: center;
      flex-direction: column;
      border-radius: 1.375rem;
      padding: 2rem;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0.04)
        ),
        linear-gradient(0deg, rgba(0, 224, 181, 0.01), rgba(0, 224, 181, 0.01)),
        $charcoal;
      box-shadow: 0 0.375rem 0.625rem 0.25rem rgba(0, 0, 0, 0.15),
        0 0.125rem 0.1875rem rgba(0, 0, 0, 0.3);
      margin: 0 0.75rem;
      .icon {
        height: 4.375rem;
        width: 4.375rem;
        transform: rotate(7.95deg);
        color: $muiPrimary;
      }
      .title {
        margin: 0.75rem 0;
      }
      .body {
        text-align: left;
        color: $muiOnSurface;
      }
      &.middle {
        .icon {
          transform: rotate(86.11deg);
        }
      }
    }
  }

  .backgroundBox {
    position: relative;
    width: 100%;

    .brightEBackground {
      position: absolute;
      bottom: -43.8275rem;
      left: -43.8275rem;
      max-width: 99.4144rem;
      max-height: 99.4144rem;
      pointer-events: none;
    }
  }

  .footerAlpha {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 6.25rem 0;
    width: 100%;
    background: inherit;
    z-index: 1;
    .textBox {
      .publicAlpha {
        margin-bottom: 1.5rem;

        .alphaHeader {
          color: $muiPrimary;
          font-weight: 400;
        }
        .label {
          margin-bottom: 1.5rem;
        }
      }
      .body {
        font-weight: 700;
      }

      .reminder {
        color: $muiOnSurfaceVariant;
      }

      .title {
        margin-bottom: 0.75rem;
      }
    }

    .discordBox {
      min-width: 15.625rem;

      .joinOurDiscord {
        display: flex;
        justify-content: center;
        margin: 1.5625rem 0;
        .logo {
          margin-right: 0.75rem;
        }
      }
      iframe {
        max-width: 15.625rem;
        max-height: 17.625rem;
        border-radius: 0.3125rem;
      }
    }
  }

  .footerMission {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $charcoal;
    background-color: $muiPrimary;
    padding: 4.6875rem 0 3.9375rem;

    .headline {
      max-width: 46.5rem;
    }

    .createButton {
      border-radius: 6.25rem;
      padding: 0.625rem 1.5rem 0.625rem 1rem;
      margin-top: 2.875rem;
      max-width: 17.5rem;
      max-height: 3rem;
      width: 100%;
      height: 100%;
      background-color: $charcoal;
      color: $muiPrimary;

      :first-child {
        margin-right: 0.5rem;
      }
      .label {
        text-transform: capitalize;
      }
    }
    .label2 {
      margin-top: 0.9375rem;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}
